.page-layout{
    background-color: #1e1e1e;
    color: #fff;
    min-height: 100vh;
}

.container{
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 15px;
}

.page-header{
    background-color: #252525;
    padding: 15px 0;
}

.page-header .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__logo{
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}
.header__logo img{
    width: 40px;
    margin-right: 10px;
}

.header__language{
    display: flex;
    align-items: center;
}

.header__language span{
    background: transparent;
    border: 1px solid #4e4b4b;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    height: 38px;
    margin-left: 10px;
    outline: none;
    transition: all .3s;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.header__language span.--active{
    background-color: #ffffff;
    color: #252525;
}

.page-content{
    padding: 50px 0;
}

.page-content h1{
    text-align: center;
    font-size: 60px;
    margin-bottom: 80px;
}

.page-content p{
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 22px;
    margin-bottom: 24px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .page-content h1{
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 30px;
    }
    .page-content p{
        font-size: 16px;
    }
}